import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Button from "../components/Button";
import { StaticImage } from "gatsby-plugin-image";

const Contact = () => (
  <Layout>
    <Seo title="Contact" />

    <section className={'relative h-screen flex items-center'}>
      <div className="absolute inset-0 bg-black pointer-events-none -z-10" aria-hidden="true">
        <div className="w-full h-full">
          <StaticImage
            className={"opacity-20 w-full h-full object-cover"}
            src={`../images/bg.jpg`} alt={'Hero'}
          />
        </div>
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 flex flex-col justify-center items-center">

        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="font-bold uppercase text-5xl font-sans text-white mb-8">Thanks for your enquiry<span className={'text-red'}>.</span></h1>
        </div>

        <p className={"text-gray-300 text-center text-xl mb-8"}>
          Somebody will be in touch with you soon.<br/>
          Alternatively, please give us
          a call on <a href={"tel:01603455368"}
                       className={`text-red transition font-bold ease-in-out duration-300 hover:text-red-500 hover:underline`}
        >
          01603 455368
        </a><br/>
          if your enquiry / booking is urgent.
        </p>

        <Button className={`bg-red-700 border-red-700 hover:bg-red-500 text-white`}
                path={'/'}
                text={'Go back To Home'}
        />

      </div>
    </section>

  </Layout>
)

export default Contact;
